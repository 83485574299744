import { PageRendererProps } from "gatsby";
import React, { Component } from "react";
import i18n from "i18next";

import Layout from "src/components/Layout";
import { Bold10 } from "src/components/Typography";
import ProductPage from "src/components/page/ProductPage";
import { storage } from "src/configs/cookieStorage";
import SEO from "src/components/SEO";
import { initialize as initializeI18next } from "src/configs/locale";

type Props = PageRendererProps;

initializeI18next("en");

class Product extends Component<Props> {
  public componentDidMount() {
    i18n.changeLanguage("en");
    storage().saveLanguage("en");
    // initializeChannelIO("en");
  }

  public render() {
    return (
      <React.Fragment>
        <SEO
          description={`Henesis is the most powerful and secure crypto wallet API. Provide great user experience and secure digital assets with Henesis. You can integrate it within two days.`}
        />
        <ProductPage {...this.props} isKo={false} />
      </React.Fragment>
    );
  }
}

export default Product;
